<template>
    <div>
        <a-form-model layout="vertical" :model="formData" @submit="handleSubmit" label="aaaaaaa">
            <a-form-model-item>
                <a-checkbox-group v-model="formData.checkedList" :options="plainOptions" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary"
                          html-type="submit">
                    提交
                </a-button>
            </a-form-model-item>
        </a-form-model>

    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
                plainOptions: [
                    { label: "选项1", value: "1" },
                    { label: "选项2", value: "2" },
                    { label: "选项3", value: "3" },
                    { label: "选项4", value: "4" },
                ],
                formData: {
                    checkedList: ["3"]
                }
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            handleSubmit: function () {
                console.log(this.formData);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>